/* @flow */

import type { SortableProductList } from "shop-state/types";

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useData, useSendMessage } from "crustate/react";
import { BrandData } from "data";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import { load as loadBrand } from "@crossroads/shop-state/brand";
import Filterbar from "components/Filterbar";
import { useFilter, OffCanvasFilterMenu, ActiveFilters } from "@crossroads/ui-components";
import { purgeBucketFromFilterState, constructSortableValues } from "helpers/filters";
import { useOpenFiltermenu } from "helpers/use-open-filtermenu";
import { Title } from "components/UiComponents";

import styles from "./styles.scss";

type BrandProps = {
  brand: { brand: string, data: SortableProductList, state: "LOADED" }
  | { brand: string, data: SortableProductList, state: "UPDATING" },
};

type Props = {
  hint?: { brand: { name: string } },
};

const BrandViewWrapper = ({ hint }: Props) => {
  const brand = useData(BrandData);

  if (hint && brand.state !== "LOADED" && brand.state !== "UPDATING") {
    return <BrandHintView hint={hint} />;
  }

  if (brand.state === "LOADED" || brand.state === "UPDATING") {
    return <BrandView brand={brand} />;
  }

  return null;
};

const BrandHintView = ({ hint }: { hint: { brand: { name: string } } }) => {
  return (
    <Wrapper>
      <Breadcrumbs current={hint.brand.name} />

      <Title>{hint.brand.name}</Title>
      <ProductList
        products={Array.from({ length: 8 }, () => null)}
        breadcrumbLink={{ brand: hint.brand.name }} />
    </Wrapper>
  );
};

const BrandView = ({ brand }: BrandProps) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const updating = brand.state === "UPDATING";
  const openFiltermenu = useOpenFiltermenu();
  const nonLinearSlider = true;

  const { routes: { allProductsView } } = useContext(StoreInfoContext);
  const parentLink = { name: allProductsView.title, url: allProductsView.url };

  const useFilterStateConfig = {
    loading: updating,
    productList: brand.data,
    usePoints: true,
    incVat: false,
    load: location => {
      sendMessage(loadBrand(brand.brand, location, false));
    },
  };

  const filterState = purgeBucketFromFilterState("manufacturer", useFilter(useFilterStateConfig));

  return (
    <Wrapper className={styles.brandView}>
      <Helmet
        title={brand.brand}
      />

      <Breadcrumbs parentLink={parentLink} current={brand.brand} />

      <div className={styles.header}>
        <Title>{brand.brand}</Title>
        <p className={styles.total_count}>{t("FILTER.ACTIVE_FILTERS.TOTAL_COUNT", { count: filterState.totalCount })}</p>
      </div>

      <div className={styles.activeFiltersRow}>
        {filterState.active.filters.length > 0 &&
          <h2 className={styles.activeFiltersRowHeader}>
            {t("FILTER.ACTIVE_FILTERS.COUNT")}
            <span className={styles.activeFiltersRowCount}>{` (${filterState.active.filters.length})`}</span>
          </h2>
        }
        <ActiveFilters
          hideEmptyFilters
          filterState={filterState}
          className={styles.activeFilters}
        />
      </div>

      <Filterbar
        openFilters={openFiltermenu.openFiltermenu}
        className={styles.filterbar}
        filterState={{
          ...filterState,
          sort: {
            ...filterState.sort,
            values: Array.isArray(filterState.sort.values) ?
              constructSortableValues(filterState.sort.values, t) :
              [],
          },
        }} />

      {brand.data.items.length > 0 ?
        <ProductList
          products={brand.data.items}
          breadcrumbLink={{ brand: brand.brand }}
          loading={updating}
          listName={brand.brand}
        /> :
        <p>{t("BRAND.EMPTY")}</p>
      }

      <OffCanvasFilterMenu
        isOpen={openFiltermenu.isOpen}
        close={openFiltermenu.closeFiltermenu}
        filterState={filterState}
        nonLinearSlider={nonLinearSlider}
        exponentiation={4}
        containerClass="awardit-OffCanvasFilterMenu"
      />
    </Wrapper>
  );
};

export default BrandViewWrapper;
