/* @flow */

import React, { useState, useCallback, useEffect, useContext } from "react";
import { useData, useSendMessage } from "crustate/react";
import { ReportFormData, VerifyListData, ReportListData } from "data";
import { loadVerifyList } from "state/verify-list";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { postVerificationForm } from "queries";
import { useTranslate } from "@awardit/react-use-translate";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";
import { Dropdown, DropdownItem } from "@crossroads/ui-components";
import { Title } from "components/UiComponents";
import TableLarge, { TableLargeHint } from "components/ReportsView/ApprovalView/TableLarge";
import TableSmall, { TableSmallHint } from "components/ReportsView/ApprovalView/TableSmall";

import styles from "./styles.scss";

const ReportsHistoryView = () => {
  const reportData = useData(ReportFormData);
  const verifyList = useData(VerifyListData);
  const reportList = useData(ReportListData);
  const [currentDistrict, setCurrentDistrict] = useState<string>(reportData.state === "LOADED" &&
  reportData.data.id !== null ? reportData.data.id : "");
  const sendMessage = useSendMessage();
  const [submitting, setSubmitting] = useState(false);
  const { routes, content: { reportsview } } = useContext(StoreInfoContext);
  const client = useClient();
  const t = useTranslate();

  const load = useCallback(() => {
    sendMessage(loadVerifyList({ district: currentDistrict }));
  }, [sendMessage, loadVerifyList, verifyList, submitting, currentDistrict]);

  useEffect(() => {
    setCurrentDistrict(reportData.state === "LOADED" && reportData.data.id !== null ?
      reportData.data.id : "");
  }, []);

  useEffect(() => {
    load();
  }, [currentDistrict]);

  const sendFormData = async items => {
    if (reportData.state === "LOADED" && reportData.data.id) {
      const ids = [];
      const status = [];

      items.forEach(item => {
        ids.push(item.id);
        status.push(item.status);
      });

      const formData = {
        id: reportData.data.id,
        chkid: ids,
        chkstatus: status,
      };

      setSubmitting(true);

      const response = await client(postVerificationForm, { form: (formData: any) });

      if (response.postVerificationForm.result.includes("ERROR")) {
        setSubmitting(false);
      }
      else {
        setSubmitting(false);
        load();
      }
    }
  };

  return (
    <Wrapper className={styles.wrapper}>
      <Helmet
        title={reportsview.approvalsTitle}
      />

      <Breadcrumbs current={reportsview.approvalsTitle} />

      <div className={styles.intro}>
        {reportsview.approvalsTitle && <Title>{reportsview.approvalsTitle}</Title>}
        {reportsview.approvalsDescription && <p>{reportsview.approvalsDescription}</p>}
        <Link className={styles.link} to={routes.reportsView.url}>{t("REPORTS.BACK")}</Link>
      </div>

      {reportList.state === "LOADED" &&
      reportList.data &&
        <Dropdown
          className={styles.districtSelector}
          name="productName"
          variant="primary"
          value={currentDistrict}
          onChange={(value: any) => {
            setCurrentDistrict(value);
          }}
        >
          {reportList.data.map(item => (
            <DropdownItem
              key={`from_${item.id}`}
              className={styles.inputWrapper}
              value={`${item.id}`}
            >
              {`${item.name}`}
            </DropdownItem>
          ))}
        </Dropdown>
      }

      {reportsview.approvalsSubTitle && <h2>{reportsview.approvalsSubTitle}</h2>}
      {verifyList.state === "LOADED" &&
      verifyList.data.header &&
      verifyList.data.table &&
        <TableLarge
          table={verifyList.data.table}
          headers={verifyList.data.header}
          isSubmitting={submitting}
          onSubmit={items => sendFormData(items)}
        />
      }
      {verifyList.state === "LOADED" &&
      verifyList.data.header &&
      verifyList.data.table &&
        <TableSmall
          table={verifyList.data.table}
          headers={verifyList.data.header}
          onSubmit={items => sendFormData(items)}
        />
      }
      {verifyList.state === "LOADED" &&
      !verifyList.data.table &&
      !verifyList.data.header &&
      reportsview.approvalsNoResults &&
        <p>{reportsview.approvalsNoResults}</p>
      }
      {verifyList.state !== "LOADED" &&
        <>
          <TableLargeHint />
          <TableSmallHint />
        </>
      }
    </Wrapper>
  );
};

export default ReportsHistoryView;
