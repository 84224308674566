/* @flow */

import type { InfoBlock } from "shop-state/types";

import React, { useContext, useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { useBrowser } from "@awardit/react-use-browser";
import { Helmet } from "react-helmet-async";
import { HomeData, CustomerData, CurrentPageInfoData, PhotoServiceListData, CurrentInfoListData, AffiliateListSelectedData, ViewModeData } from "data";
import ProductCard, { DummyCard } from "components/ProductCard";
import ProductCarousel from "components/ProductCarousel";
import PopularCategoryCarousel from "components/PopularCategoryCarousel";
import Wrapper from "components/Wrapper";
import HomeHero from "components/HomeHero";
import HomeHeroLeaderboard from "components/HomeHeroLeaderboard";
import UploadImagesOverlay from "components/UploadedImages/UploadImagesOverlay";
import Button from "components/Button";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import { getCurrentPageInfo } from "state/current-page-info";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import { Title, Description, Ingress, Items, Item } from "components/UiComponents";
import { getCustomerData, getNumberBasedOnBrowserWidth, tryParseJSONObject } from "helpers/utils";
import Agreement from "components/Agreement";
import AffiliateList, { AffiliateDummyList } from "components/AffiliateList";
import USPSection from "components/USPSection";
import useNotifyMissingAccountDetails from "helpers/use-notify-missing-account-details";
import { useTranslate } from "@awardit/react-use-translate";
import { logout, syncCustomer } from "@crossroads/shop-state/customer";
import { awarditAgreementAgree, customer as customerQuery } from "queries";
import { MODE } from "state/view-mode";

import styles from "./styles.scss";

const HomeView = () => {
  const {
    routes,
    content: {
      homeview: content,
      uploadedImagesView: imagesview,
      allproductsview: { popularCategories, popularCategoriesTitle },
      productCarousel: { useOnHomeView },
    },
    info: { code },
    configuration,
  } = useContext(StoreInfoContext);
  const home = useData(HomeData);
  const viewMode = useData(ViewModeData);
  const affiliateList = useData(AffiliateListSelectedData);
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const { width: browserWidth } = useBrowserDimensions();
  const customer = getCustomerData(useData(CustomerData));
  const [showTerms, setShowTerms] = useState(false);
  const isBrowser = useBrowser();
  const client = useClient();
  const currentPageInfoData = useData(CurrentPageInfoData);
  const currentInfoListData = useData(CurrentInfoListData);
  const photoServiceListData = useData(PhotoServiceListData);
  const [uploadImgOpen, setUploadImgOpen] = useState(false);
  const popularCategoriesJson = popularCategories !== undefined &&
    tryParseJSONObject(popularCategories) ? JSON.parse(popularCategories) : "";
  const cookieConsentIsVisible = viewMode === MODE.COOKIE_CONSENT;

  const memberTargetList = customer &&
    customer.memberTargetList &&
    customer.memberTargetList.list.length > 0 ?
    customer.memberTargetList.list : [];

  const currentInfoArray = currentInfoListData.state === "LOADED" &&
  currentInfoListData.data &&
  currentInfoListData.data.length > 0 ? currentInfoListData.data : [];

  const numAffiliates = getNumberBasedOnBrowserWidth(
    styles,
    { small: 4, medium: 6 },
    8,
    browserWidth
  );

  const onSuccess = () => {
    setUploadImgOpen(false);
  };

  useEffect(() => {
    sendMessage(getCurrentPageInfo("HOMEVIEW"));
    sendMessage(getCurrentPageInfo("NEWSVIEW"));
    sendMessage(getCurrentPageInfo("OFFERLISTVIEW"));
    sendMessage(getCurrentPageInfo("CURRENTINFO6"));
  }, []);

  useEffect(() => {
    if (isBrowser &&
      configuration.showTermsOnFirstLogin === true &&
      customer &&
      customer.awardit.agreementAccepted === false) {
      setShowTerms(true);
    }
  }, [isBrowser, customer, configuration]);

  const postAgreement = async () => {
    const res = await client(awarditAgreementAgree);
    if (res.awarditAgreementAgree === "success") {
      await client(customerQuery).then(({ customer }) => {
        sendMessage(syncCustomer(customer));
      });
      setShowTerms(false);
    }
  };

  useNotifyMissingAccountDetails();

  const currentInfoOrder = content.currentInfoOrder ?
    Number.parseInt(content.currentInfoOrder, 10) :
    1;
  const featuredContentOrder = content.featuredContentOrder ?
    Number.parseInt(content.featuredContentOrder, 10) :
    2;
  const featuredProductsOrder = content.featuredProductsOrder ?
    Number.parseInt(content.featuredProductsOrder, 10) :
    3;
  const uploadImageOrder = content.uploadImageOrder ?
    Number.parseInt(content.uploadImageOrder, 10) :
    4;
  const additionalHtmlOrder = content.additionalHtmlOrder ?
    Number.parseInt(content.additionalHtmlOrder, 10) :
    5;
  const additionalHtmlSecondaryOrder = content.additionalHtmlSecondaryOrder ?
    Number.parseInt(content.additionalHtmlSecondaryOrder, 10) :
    6;
  const popularProductsOrder = content.popularProductsOrder ?
    Number.parseInt(content.popularProductsOrder, 10) :
    -1;
  const popularCategoriesOrder = content.popularCategoriesOrder ?
    Number.parseInt(content.popularCategoriesOrder, 10) :
    -1;
  const earnOnlineOrder = content.earnOnlineOrder ?
    Number.parseInt(content.earnOnlineOrder, 10) :
    6;
  const howToEarnOrder = content.howToEarnOrder ?
    Number.parseInt(content.howToEarnOrder, 10) :
    3;
  const currentInfo6Order = content.currentInfo6Order ?
    Number.parseInt(content.currentInfo6Order, 10) :
    -1;
  const getItemAmount = (config, defaultAmount) => {
    if (browserWidth > 0) {
      const currentBreakpoint = Object.keys(config).find(breakpoint => {
        return browserWidth <= parseInt(styles[breakpoint], 10);
      });

      return currentBreakpoint ? config[currentBreakpoint] : defaultAmount;
    }

    return 0;
  };

  const newsCurrentInfo = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.newsview &&
    currentPageInfoData.data.newsview.length > 0 ?
    currentPageInfoData.data.newsview.slice(0, 5) : [];

  const newsCurrentInfoChunks: Array<Array<InfoBlock>> = [];

  if (currentPageInfoData.state === "LOADED" && currentPageInfoData.data.newsview && currentPageInfoData.data.newsview.length > 0) {
    newsCurrentInfoChunks.push(newsCurrentInfo.slice(0, 3));
  }

  const featuredProductsAmount = getItemAmount({ small: 2, medium: 3 }, 4);

  const featuredProducts = useMemo(() => home.state !== "LOADED" ? [...new Array(4)] :
    home.data.featuredProducts && home.data.featuredProducts.filter(p => !memberTargetList ||
      memberTargetList.includes(p.attributes.awarditTargetId) ||
      !p.attributes.awarditTargetId)
      .sort(() => 0.5 - Math.random())
      .slice(0, featuredProductsAmount
      ), [home.state, featuredProductsAmount]);

  const currentInfoHomeView = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.homeview &&
    currentPageInfoData.data.homeview.length > 0 ? currentPageInfoData.data.homeview : [];

  const affiliateItems = (affiliateList.state === "LOADED" ? affiliateList.data : []).slice(0, numAffiliates);

  const currentInfo6Count = Number.parseInt(content.currentInfo6Count, 10);
  const currentInfo6Array = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.currentinfo6 &&
    currentPageInfoData.data.currentinfo6.length > 0 ?
    (currentInfo6Count > 0 ?
      currentPageInfoData.data.currentinfo6.slice(0, currentInfo6Count) :
      currentPageInfoData.data.currentinfo6) : [];

  const earnOnlineSection = (
    <Wrapper key="earnOnline" className={cn(styles.section, styles.earnOnlineSection)}>
      <div className={styles.top}>
        <Title elem="h2">{content.earnOnlineHeading}</Title>
        {content.earnOnlineButtonLink &&
        <div className={styles.earnOnline}>
          <Link
            to={content.earnOnlineButtonLink}
            className={cn("link", styles.cta)}
          >
            {content.earnOnlineButtonText}
          </Link>
        </div>
        }
      </div>
      {affiliateList.state === "LOADED" &&
        <AffiliateList items={affiliateItems} />
      }

      {affiliateList.state === "LOADING" &&
        <AffiliateDummyList items={Array.from({
          length: numAffiliates,
        }, () => null)} />
      }
    </Wrapper>
  );

  const featuredProductsSection = (
    <Wrapper className={styles.wrapper}>
      <div key="featuredProducts" className={styles.section}>
        <div className={styles.top}>
          {(content.featuredProductsHeading || content.featuredProductsDescription) && (
            <Ingress className={styles.ingress}>
              {content.featuredProductsHeading && (
                <Title className={styles.title} elem="h2">{content.featuredProductsHeading}</Title>
              )}
              {content.featuredProductsDescription && (
                <Description
                  className={cn(styles.description, "awardit-description")} content={content.featuredProductsDescription} />
              )}
            </Ingress>
          )}
          {content.featuredProductsButtonLink &&
            <div className={styles.featuredProducts}>
              <Link
                to={content.featuredProductsButtonLink}
                className={cn("link", styles.cta)}
              >
                {content.featuredProductsButtonText}
              </Link>
            </div>
          }
        </div>
        <Items>
          {featuredProducts && featuredProducts.map((p, i) =>
            p ?
              <Item key={p.name}>
                <ProductCard product={p} list={content.featuredProductsHeading} position={i} />
              </Item> :
              <Item key={i}><DummyCard /></Item>
          )}
        </Items>
      </div>
    </Wrapper>
  );

  const featuredContentSection = (
    currentInfoArray &&
    currentInfoArray.length > 0 &&
    <div key="featuredContent" className={cn(styles.featuredContentSection, "awardit-featuredContentSection")}>
      <Wrapper className={styles.darkBgWrapper}>
        <Title className={styles.title} elem="h2">{content.currentInfoTitle}</Title>
        <div className={styles.currentPageInfoFeatured}>
          {currentInfoArray.map((info, i) => (
          /* eslint-disable react/no-danger */
            <div key={"offerlist_item_" + i} className={styles.currentInfoCardWrapper} idx={i}>
              <div className={cn(styles.currentInfoCard, "awardit-currentInfoCard")}>
                <div>
                  <img src={info.image} />
                </div>
                {info.fromDate &&
                  <p className={styles.currentInfoCardDate}>{info.fromDate}</p>
                }
                <div className={styles.currentInfoCardContent}>
                  <h3>{info.title}</h3>
                  <div
                    className={cn(styles.currentInfoDescription, "awardit-currentInfoDescription")}
                    dangerouslySetInnerHTML={{ __html: info.content }} />
                </div>
              </div>
            </div>
          /* eslint-enable react/no-danger */
          ))}
        </div>
      </Wrapper>
    </div>
  );

  const currentPageInfo = (
    currentPageInfoData.state === "LOADED" &&
    currentInfoHomeView.length > 0 &&
    <div key="currentPageInfo" className={cn(styles.currentPageInfoGrid, "awardit-currentPageInfoGrid")}>
      <Wrapper className={styles.darkBgWrapper}>
        <Title className={styles.title} elem="h2">{content.currentInfoTitle}</Title>
        <div className={cn(styles.currentInfoSectionGrid, "awardit-currentInfoSectionGrid")}>
          {/* HeroView left column (7fr) */}
          <div className={styles.currentInfoCardsWrapper}>
            <div className={styles.currentInfoCardWrapper}>
              <div className={cn(styles.currentInfoCard, "awardit-currentInfoCard")}>
                <div>
                  <img src={currentInfoHomeView[0].image} />
                </div>
                <div className={styles.currentInfoCardContent}>
                  <h3>{currentInfoHomeView[0].title}</h3>
                  {/* eslint-disable react/no-danger */}
                  <p
                    className={cn(styles.currentInfoDescription, "awardit-currentInfoDescription")}
                    dangerouslySetInnerHTML={{ __html: currentInfoHomeView[0].content }} />
                  {/* eslint-enable react/no-danger */}
                </div>
              </div>
            </div>
            {currentInfoHomeView.slice(3).map<React$Node>((info, i) => (
              <div key={"offerlist_item_" + i} className={styles.currentInfoCardWrapper} idx={i}>
                <div className={cn(styles.currentInfoCard, "awardit-currentInfoCard")}>
                  <div>
                    <img src={info.image} />
                  </div>
                  <div className={styles.currentInfoCardContent}>
                    <h2>{info.title}</h2>
                    {/* eslint-disable react/no-danger */}
                    <p
                      className={cn(styles.currentInfoDescription, "awardit-currentInfoDescription")}
                      dangerouslySetInnerHTML={{ __html: info.content }} />
                    {/* eslint-enable react/no-danger */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* HeroView right column (3fr) */}
          <div className={styles.currentInfoCardsWrapper}>
            {content.leadsInfoBoxStatus === true &&
            content.leadsInfoBoxTitle &&
            content.leadsInfoBoxDescription &&
            content.leadsInfoBoxImage &&
            <div className={styles.currentInfoCardWrapper}>
              <div className={cn(styles.currentInfoCard, styles.leadsWrapper, "awardit-leadsWrapper")}>
                <div>
                  <img src={content.leadsInfoBoxImage} />
                </div>
                <div className={styles.currentInfoCardContent}>
                  <h3>{content.leadsInfoBoxTitle}</h3>
                  <div className={cn(styles.currentInfoDescription, "awardit-currentInfoDescription")}>
                    <p>{content.leadsInfoBoxDescription}</p>
                    {content.leadsInfoBoxButtonText &&
                      <a href="/leads">{content.leadsInfoBoxButtonText}</a>
                    }
                  </div>
                </div>
              </div>
            </div>
            }
            {currentInfoHomeView.slice(1, 3).map<React$Node>((info, i) => (
              <div key={"offerlist_item_" + i} className={styles.currentInfoCardWrapper} idx={i}>
                <div className={cn(styles.currentInfoCard, "awardit-currentInfoCard")}>
                  <div>
                    <img src={info.image} />
                  </div>
                  <div className={styles.currentInfoCardContent}>
                    <h3>{info.title}</h3>
                    {/* eslint-disable react/no-danger */}
                    <div
                      className={cn(styles.currentInfoDescription, "awardit-currentInfoDescription")}
                      dangerouslySetInnerHTML={{ __html: info.content }} />
                    {/* eslint-enable react/no-danger */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {code === "tele2_sek" &&
          <div className={styles.newsButton}>
            <Link
              to="/nyheter"
            >
              {t("HOMEVIEW.MORE_NEWS")}
            </Link>
          </div>
        }
      </Wrapper>
    </div>
  );

  const howToEarnSection =
  (content.howToEarnDescription || content.howToEarnHeading || content.howToEarnImage) ? (
    <Wrapper className={styles.wrapper}>
      <div key="howToEarn" className={styles.promotion}>
        <div className={cn(styles.left, !content.howToEarnImage ? styles.empty : "")}>
          {content.howToEarnImage &&
            <img src={content.howToEarnImage} />
          }
        </div>
        <div className={styles.right}>
          <Title className={styles.title} elem="h2">{content.howToEarnHeading}</Title>
          <Description className={cn(styles.description, "awardit-description")} content={content.howToEarnDescription} />

          {content.howToEarnButtonLink &&
            <Link
              to={content.howToEarnButtonLink}
              className={cn("link", styles.ctaLarge)}
            >
              {content.howToEarnButtonText}
            </Link>
          }
        </div>
      </div>
    </Wrapper>
  ) : null;

  const uploadImageSection = (
    routes.photoServiceView &&
    routes.photoServiceView.toggle &&
    <Wrapper className={styles.wrapper}>
      <div key="uploadImageSection" className={cn("awardit-homeViewUploadedImages", styles.section)}>
        <UploadImagesOverlay
          open={uploadImgOpen}
          setOpen={setUploadImgOpen}
          onSuccess={onSuccess}
        />
        <div className={cn("awardit-homeViewUploadedImagesTop", styles.top)}>
          <Title className={styles.title} elem="h2">
            {routes.photoServiceView &&
              routes.photoServiceView.title ?
              routes.photoServiceView.title :
              t("IMAGE_UPLOADER.LATEST")}
          </Title>
          <div className={cn("awardit-homeViewUploadedImagesTopCta", styles.uploadImage)}>
            <Link
              to={routes.photoServiceView && routes.photoServiceView.url ? routes.photoServiceView.url : ""}
              className={cn("link", styles.cta)}
            >
              {t("IMAGE_UPLOADER.SHOW_ALL")}
            </Link>
            <Button
              type="button"
              className={styles.btn}
              variant="primary"
              onClick={() => setUploadImgOpen(true)}
            >
              {t("IMAGE_UPLOADER.UPLOAD_IMAGE")}
            </Button>
          </div>
        </div>
        {imagesview &&
          imagesview.frontpageInfo !== null &&
          imagesview.frontpageInfo !== undefined &&
          <>
            {/* eslint-disable react/no-danger */}
            <div
              className={styles.imageViewDesc}
              dangerouslySetInnerHTML={{ __html: imagesview.frontpageInfo }}
            />
            {/* eslint-enable react/no-danger */}
          </>
        }
        <Items>
          {photoServiceListData &&
          photoServiceListData.state === "LOADED" &&
          photoServiceListData.data.length > 0 ?
            photoServiceListData.data.slice(0, 4).map((elem, i) => (
              elem ?
                <Item key={`${elem.title}-${i}`} className={styles.uploadedImageContainer}>
                  <div className={styles.innerImageContainer}>
                    <img src={elem.thumb ? elem.thumb : elem.url} />
                  </div>
                  <p className={styles.imageContainerTitle}>{elem.title}</p>
                </Item> :
                <Item key={i}><DummyCard /></Item>
            )) : <p className={styles.imageContainerTitle}>{t("IMAGE_UPLOADER.EMPTY")}</p>
          }
        </Items>
      </div>
    </Wrapper>
  );

  const popularCategoriesSection = (
    <Wrapper className={styles.popularCategories}>
      {popularCategoriesJson &&
        <PopularCategoryCarousel
          title={popularCategoriesTitle}
          popularCategories={popularCategoriesJson}
        />
      }
    </Wrapper>
  );

  const popularProductsSection = (
    useOnHomeView && (
      <Wrapper className={styles.popularProducts}>
        <ProductCarousel />
      </Wrapper>
    )
  );

  const additionalHtmlSection = (
    content.additionalHtml && (
      <div className={styles.additionalHtmlContainer}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content.additionalHtml }} />
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );

  const additionalHtmlSecondarySection = (
    content.additionalHtmlSecondary && (
      <div className={styles.additionalHtmlContainer}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content.additionalHtmlSecondary }} />
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );

  const currentInfo6Section = (
    currentInfo6Array &&
    currentInfo6Array.length > 0 &&
    routes.currentInfoView6.url && (
      <div className="awardit-currenInfoCardsWrapper">
        <Wrapper className={styles.wrapper}>
          <Title className={styles.title} elem="h2">{content.currentInfo6Title}</Title>
          <div className={styles.currenInfoCardsSection}>
            {currentInfo6Array.map((info, i) => (
            /* eslint-disable react/no-danger */
              <div key={"currentinfo6_item_" + i} className={cn(styles.currentInfoCardWrapper, styles.currentInfoCardWrapperAlt)} idx={i}>
                <Link
                  to={{
                    pathname: `${routes.currentInfoView6.url}/${info.id}`,
                    state: { hint: { title: info.title } },
                  }}
                  className={cn(styles.currentInfoCard, styles.currentInfoCardAlt, "awardit-currenInfoCard")}
                >
                  {info.title && <h3>{info.title}</h3>}
                  {info.image &&
                    <div className={styles.currenInfoCardImage}>
                      <img src={info.image} />
                    </div>
                  }
                  {info.content &&
                    <div
                      className={cn(styles.currentInfoDescription, "awardit-currentInfoDescription")}
                      dangerouslySetInnerHTML={{ __html: info.description }}
                    />
                  }
                </Link>
              </div>
            /* eslint-enable react/no-danger */
            ))}
          </div>
        </Wrapper>
      </div>
    )
  );

  const main = [];

  if (featuredProductsOrder >= 0) {
    main[featuredProductsOrder] = featuredProductsSection;
  }

  if (currentInfoOrder >= 0) {
    main[currentInfoOrder] = currentPageInfo;
  }

  if (featuredContentOrder >= 0) {
    main[featuredContentOrder] = featuredContentSection;
  }

  if (uploadImageOrder >= 0) {
    main[uploadImageOrder] = uploadImageSection;
  }

  if (additionalHtmlOrder >= 0) {
    main[additionalHtmlOrder] = additionalHtmlSection;
  }

  if (additionalHtmlSecondaryOrder >= 0) {
    main[additionalHtmlSecondaryOrder] = additionalHtmlSecondarySection;
  }

  if (popularProductsOrder >= 0) {
    main[popularProductsOrder] = popularProductsSection;
  }

  if (popularCategoriesOrder >= 0) {
    main[popularCategoriesOrder] = popularCategoriesSection;
  }

  if (earnOnlineOrder >= 0) {
    main[earnOnlineOrder] = earnOnlineSection;
  }

  if (howToEarnOrder >= 0) {
    main[howToEarnOrder] = howToEarnSection;
  }

  if (currentInfo6Order >= 0) {
    main[currentInfo6Order] = currentInfo6Section;
  }

  return (
    <div className={cn(styles.block, "awardit-homeView")}>
      <Helmet
        title={content.pageTitle}
      />
      {content.motivationLeaderboardWidgetIsActive === true ?
        <HomeHeroLeaderboard /> : <HomeHero />}
      <div className={cn("awardit-homeViewContent")}>
        <USPSection />
        { main }
      </div>
      {showTerms && !cookieConsentIsVisible &&
        <Agreement
          agreementModalOpen={showTerms}
          setAgreementModalOpen={open => {
            if (open === true) {
              sendMessage(logout());
            }

            if (open === false) {
              postAgreement();
            }
          }}
        />
      }
    </div>
  );
};

export default HomeView;
