/* @flow */

import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

type Props = {
  className?: string,
  parentLink?: {
    name: string,
    url: string,
  },
  current?: string,
  breadcrumbLink?: {
    search?: string,
    brand?: string,
  },
  links?: $ReadOnlyArray<{ name: string, url: string }>,
};

const Slash = () => <span className={styles.slash}>/</span>;

const Breadcrumbs = ({ className, parentLink, current, breadcrumbLink, links }: Props) => {
  const t = useTranslate();
  const location = useLocation();
  const { categories, routes } = useContext(StoreInfoContext);

  const search = (!Array.isArray(links) && breadcrumbLink) ? breadcrumbLink.search : null;
  const brand = (!Array.isArray(links) && breadcrumbLink) ? breadcrumbLink.brand : null;

  const [categoryPath, subCategoryPath] = location.pathname.slice(1).split("/");
  const currentCategory = !Array.isArray(links) ? categories.find(c => c.url === `/${categoryPath}` && c.name !== current) :
    null;
  const currentSubCategory = currentCategory &&
    currentCategory.children
      .find(c => c.url === `/${categoryPath}/${subCategoryPath}` && c.name !== current);

  return (
    <div className={cn(className, styles.block, "awardit-Breadcrumbs")}>
      <div className={cn(styles.links, "awardit-BreadcrumbLinks")}>
        <Link to="/">{t("BREADCRUMBS.HOME")}</Link>
        <Slash />

        {parentLink && (
          <React.Fragment key={parentLink.url}>
            <Link to={parentLink.url}>
              {parentLink.name}
            </Link>
            <Slash />
          </React.Fragment>
        )}

        {currentCategory && (
          <React.Fragment key={currentCategory.url}>
            <Link
              to={{
                pathname: currentCategory.url,
                state: {
                  hint: {
                    type: "category",
                    category: {
                      name: currentCategory.name,
                    },
                  },
                },
              }}
            >
              {currentCategory.name}
            </Link>

            <Slash />
          </React.Fragment>
        )}

        {brand && (
          <React.Fragment key={brand}>
            <Link
              to={{
                pathname: `${routes.brandsView.url}${brand}`,
                state: {
                  hint: {
                    type: "brand",
                    category: {
                      name: brand,
                    },
                  },
                },
              }}
            >
              {brand}
            </Link>

            <Slash />
          </React.Fragment>
        )}

        {search && (
          <React.Fragment key={search}>
            <Link
              to={{
                pathname: `${routes.searchView.url}${search}`,
              }}
            >
              {t("BREADCRUMBS.SEARCH", { query: search })}
            </Link>

            <Slash />
          </React.Fragment>
        )}

        {currentSubCategory && (
          <React.Fragment key={currentSubCategory.url}>
            <Link
              to={{
                pathname: currentSubCategory.url,
                state: {
                  hint: {
                    type: "category",
                    category: {
                      name: currentSubCategory.name,
                    },
                  },
                },
              }}
            >
              {currentSubCategory.name}
            </Link>

            <Slash />
          </React.Fragment>
        )}

        {links && links.map(l => (
          <React.Fragment key={l.url}>
            <Link
              to={l.url}
            >
              {l.name}
            </Link>

            <Slash />
          </React.Fragment>
        ))}

        <span className={styles.product}>{current}</span>
      </div>
    </div>
  );
};

export default Breadcrumbs;
