/* @flow */

import type { Routes, CurrentInfoRoute } from "shop-state/types";

import React, { useContext } from "react";
import { Route } from "react-router";
import { CurrentInfoListData, CurrentInfoItemData } from "data";
import { parseParams } from "@crossroads/location-search-string";
import { useParseTags } from "helpers/use-parse-tags";
import { StoreInfoContext } from "entrypoint/shared";
import CurrentInfoListView from "components/CurrentInfo/CurrentInfoListView";
import CurrentInfoItemView from "components/CurrentInfo/CurrentInfoItemView";
import CurrentInfoContent from "./CurrentInfoContent";

const FIELDS = [
  { name: "currentInfoView1", key: "CURRENTINFO1" },
  { name: "currentInfoView2", key: "CURRENTINFO2" },
  { name: "currentInfoView3", key: "CURRENTINFO3" },
  { name: "currentInfoView4", key: "CURRENTINFO4" },
  { name: "currentInfoView5", key: "CURRENTINFO5" },
  { name: "currentInfoView6", key: "CURRENTINFO6" },
  { name: "alternateContent1", key: "ALTERNATECONTENT01" },
  { name: "alternateContent2", key: "ALTERNATECONTENT02" },
  { name: "alternateContent3", key: "ALTERNATECONTENT03" },
];

const filterCurrentInfoRoutes =
  (routes: Routes): $ReadOnlyArray<CurrentInfoRoute> => {
    const activeCurrentInfoRoutes = [];

    for (const field of FIELDS) {
      if (routes[field.name] &&
      routes[field.name].toggle &&
      routes[field.name].url &&
      routes[field.name].title) {
        activeCurrentInfoRoutes.push({
          url: routes[field.name].url,
          title: routes[field.name].title,
          key: field.key,
          name: field.name,
        });
      }
    }

    return activeCurrentInfoRoutes;
  };

const getCurrentInfoRoutes = (routes: Routes): Array<React$Node> => {
  const currentInfoRoutes = filterCurrentInfoRoutes(routes);
  /* eslint-disable react-hooks/rules-of-hooks */
  const { info: { code } } = useContext(StoreInfoContext);
  /* eslint-enable react-hooks/rules-of-hooks */

  if (currentInfoRoutes.length === 0) {
    return [];
  }

  return currentInfoRoutes.reduce((acc, curr) => {
    acc.push(
      <Route
        key={`current_info_route_${curr.key.toLowerCase()}`}
        path={`${curr.url}/:id`}
        render={({ match }) => {
          return (
            <CurrentInfoItemData.Provider
              name={`current_info_route_${curr.key.toLowerCase()}_${match.params.id}`}
              id={match.params.id}
            >
              <CurrentInfoItemView parentRoute={curr} />
            </CurrentInfoItemData.Provider>
          );
        }}
      />,
      <Route
        key={`current_info_list_route_${curr.key.toLowerCase()}`}
        exact
        path={`${curr.url}`}
        render={({ location }) => {
          const queryParams = parseParams(location.search);
          const tags = useParseTags(queryParams);

          return (
            <CurrentInfoListData.Provider
              name={`current_info_list_route_${curr.key.toLowerCase()}`}
              page={curr.key}
              tags={tags ?? []}
            >
              {curr.key.includes("ALTERNATECONTENT") && code === "tele2_sek" ?
                <CurrentInfoContent route={curr} /> : <CurrentInfoListView route={curr} />}
            </CurrentInfoListData.Provider>
          );
        }}
      />
    );

    return acc;
  }, []);
};

export default getCurrentInfoRoutes;
