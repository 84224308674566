/* @flow */

import React, { useContext, useState, useEffect } from "react";

import Wrapper from "components/Wrapper";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { Input, Dropdown, DropdownItem } from "@crossroads/ui-components";
import { Title } from "components/UiComponents";
import cn from "classnames";
import { usePointsLabel } from "helpers/points";

import styles from "./styles.scss";

type partnerInfo = {
  name: string,
  interestRate?: number | null,
  normalRate?: number | null,
  contractAmount?: number | null,
};

const CalculatorView = () => {
  const t = useTranslate();
  const label = usePointsLabel();
  const [contractCount, setContractCount] = useState(null);
  const [interest, setInterest] = useState(null);
  const [financeValue, setFinanceValue] = useState(0);
  const [result, setResult] = useState(0);
  const [error, setError] = useState(false);
  const { info, content: { calculatorView } } = useContext(StoreInfoContext);

  const dropdownValues = [...new Array(21).keys()];
  dropdownValues.shift();

  const partners = [
    { name: "sveabank_sek", interestRate: 0.03, normalRate: 0.03, contractAmount: 500 },
    { name: "svea_fin", interestRate: 0.5, normalRate: 0.25 },
  ];

  const currentPartner = partners.filter(partner => partner.name === info.code);

  const calculate = () => {
    if (contractCount !== null) {
      const contractVal = 400 * contractCount;
      const currencyValue = financeValue * 0.085;
      const value = currencyValue + contractVal;
      setResult(Math.floor(value));
    }
  };

  const calculateInterest = (partner: partnerInfo) => {
    if (interest !== null) {
      const { interestRate, normalRate, contractAmount } = partner;

      if (interest === "INTEREST") {
        const contractVal =
          (contractAmount !== undefined && contractAmount !== null && contractCount !== null) ?
            (contractAmount * contractCount) : 0;
        const interestVal =
          (interestRate !== undefined && interestRate !== null) ? (financeValue * interestRate) : 0;

        setResult(Math.floor(contractVal + interestVal));
      }
      else if (interest === "INTERESTFREE") {
        const amount =
          (normalRate !== undefined && normalRate !== null) ? (financeValue * normalRate) : 0;

        setResult(Math.floor(amount));
        setContractCount(null);
      }
    }
  };

  const validateRequiredInput = () => {
    switch (interest) {
      case "INTEREST":
        if (financeValue > 0) {
          if (currentPartner[0].contractAmount === undefined || contractCount !== null) {
            setError(false);
            return;
          }
        }

        break;
      case "INTERESTFREE":
        if (financeValue > 0) {
          setError(false);
          return;
        }

        break;
      default:
        if (currentPartner.length === 0) {
          if (contractCount !== null && financeValue > 0) {
            setError(false);
            return;
          }
        }

        break;
    }

    setError(true);
  };

  useEffect(() => {
    if (contractCount !== null || interest !== null || financeValue > 0) {
      let idMatch = false;
      partners.forEach(partner => {
        if (info.code === partner.name) {
          calculateInterest(partner);
          idMatch = true;
        }
      });
      if (idMatch === false) {
        calculate();
      }

      validateRequiredInput();
    }
    else {
      setResult(0);
    }
  }, [contractCount, financeValue, interest]);

  return (
    <Wrapper className={styles.wrapper}>
      <Title className={styles.title}>{calculatorView.heading}</Title>
      <p className={styles.description}>{calculatorView.introText}</p>
      <div className={styles.inputContainer}>
        {/* Interest dropdown exclusively for SVEA */}
        {(partners.filter(partner => partner.name === info.code).length > 0) &&
          <Dropdown
            className={styles.dropdown}
            placeholder={t("CALCULATOR.PLACEHOLDER_INTEREST")}
            value={interest}
            onChange={value => {
              setInterest(value);
              validateRequiredInput();
            }}
          >
            <DropdownItem key={1} value="INTEREST">
              {t("CALCULATOR.DROPDOWN_INTEREST")}
            </DropdownItem>
            <DropdownItem key={2} value="INTERESTFREE">
              {t("CALCULATOR.DROPDOWN_INTERESTFREE")}
            </DropdownItem>
          </Dropdown>
        }
        <Input
          type="number"
          className={styles.currencyInput}
          wrapperClassName={styles.inputWrapper}
          placeholder={t("CALCULATOR.PLACEHOLDER_AMOUNT")}
          min="0"
          onChange={e => {
            if (e.target.value >= 0) {
              const val = e.target.value;
              setFinanceValue(parseInt(val, 10));
              validateRequiredInput();
            }
          }} />
        <Dropdown
          className={styles.dropdown}
          placeholder={t("CALCULATOR.PLACEHOLDER_COUNT")}
          value={contractCount}
          disabled={
            (interest === "INTERESTFREE") ||
            (interest === "INTEREST" && currentPartner[0].contractAmount === undefined)
          }
          onChange={value => {
            setContractCount(parseInt(value, 10));
            validateRequiredInput();
          }}
        >
          {dropdownValues.map(num => (
            <DropdownItem key={num} value={num}>
              {num}
            </DropdownItem>
          ))}
        </Dropdown>
        {error ?
          <div className={cn(styles.container, styles.error)}><p>{t("CALCULATOR.ERROR")}</p></div> :
          <p className={styles.result}>{result + ` ${t("CALCULATOR.POINTS", { pointsLabel: label() })}`}</p>}
      </div>
    </Wrapper>
  );
};

export default CalculatorView;
