/* @flow */

import React, { useContext } from "react";
import { Lightbox } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import Button from "components/Button";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

type ModalProps = {
  modalOpen: boolean,
  setModalOpen: (open: boolean) => void,
  customerEmail: string,
  updateStep: () => void,
  currentStep: number,
  loading: boolean,
};

const VerifyEmailView = ({
  modalOpen,
  setModalOpen,
  customerEmail,
  updateStep,
  currentStep,
  loading }: ModalProps) => {
  const t = useTranslate();
  const { content: { verifyEmailView } } = useContext(StoreInfoContext);
  /* eslint-disable react/no-danger */
  return (
    <Lightbox
      className={styles.verifyEmailView}
      open={modalOpen}
      setOpen={() => setModalOpen(true)}
    >
      <div className={styles.top}>
        {verifyEmailView.firstHeader && verifyEmailView.secondHeader &&
          <div className={styles.header}>
            {currentStep === 1 ?
              <h2>{verifyEmailView.firstHeader}</h2> :
              <>
                <h2>{verifyEmailView.secondHeader}</h2>
                <p>{t("VERIFY_EMAIL.SECOND_STEP.HEADER_TEXT.FIRST_ROW")}<strong>{t("VERIFY_EMAIL.MAIL_ADDRESS", { email: customerEmail })}</strong></p>
                <p>{t("VERIFY_EMAIL.SECOND_STEP.HEADER_TEXT.SECOND_ROW")}</p>
              </>
            }
          </div>
        }
        {verifyEmailView.firstContent && verifyEmailView.secondContent &&
          <div className={styles.content}>
            {currentStep === 1 ?
              <p>{t("VERIFY_EMAIL.FIRST_STEP.TEXT.FIRST_PART")}<strong>{t("VERIFY_EMAIL.MAIL_ADDRESS", { email: customerEmail })}</strong></p> :
              <>
                <p><strong>{t("VERIFY_EMAIL.SECOND_STEP.TEXT.NO_MAIL.HEADER")}</strong></p>
                <p>{t("VERIFY_EMAIL.SECOND_STEP.TEXT.NO_MAIL.TEXT")} <span className={styles.tryAgainButton} onClick={() => updateStep()}>{t("VERIFY_EMAIL.SECOND_STEP.TEXT.NO_MAIL.HERE")}</span></p>
              </>
            }
            <div
              dangerouslySetInnerHTML={{ __html: currentStep === 1 ?
                verifyEmailView.firstContent : verifyEmailView.secondContent }} />
          </div>
        }
      </div>

      <div className={styles.bottom}>
        {currentStep === 1 ?
          <Button variant="primary" loading={loading} size="small" onClick={() => updateStep()}>{verifyEmailView.firstButtonText}</Button> :
          <Button variant="primary" loading={loading} size="small" onClick={() => setModalOpen(false)}>{verifyEmailView.secondButtonText}</Button>
        }
      </div>
    </Lightbox>
  );
  /* eslint-enable react/no-danger */
};

export default VerifyEmailView;
