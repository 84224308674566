/* @flow */

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import SystemMessages from "components/SystemMessages";
import Wrapper from "components/Wrapper";
import Logo from "components/AppHeader/Logo";
import cn from "classnames";
import useIsSmall from "helpers/use-is-small";
import { getFavicons } from "helpers/get-meta";
import { useData, useSendMessage } from "crustate/react";
import VerifyEmailView from "components/VerifyEmailView";
import CookieConsent from "components/CookieConsent";
import Button from "components/Button";
import { CustomerData, CmsData } from "data";
import { sendEmailVerification } from "state/customer";
import { setMode, MODE } from "state/view-mode";

import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

type Props = {
  children: React$Node,
  location: Location,
};

const GuestApp = ({ children, location }: Props) => {
  const {
    info,
    configuration,
    routes,
    content: { loginview },
  } = useContext(StoreInfoContext);
  const isSmall = useIsSmall(parseInt(styles.small, 10));
  const sendMessage = useSendMessage();
  const [currentStep, updateStep] = useState<number>(1);
  const [email, setEmail] = useState<string>("");
  const customerData = useData(CustomerData);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const loading = customerData.state === "SENDING_VERIFICATION";
  const t = useTranslate();
  const consentActivated = (configuration.showCookieConsent !== null ||
    configuration.showCookieConsent !== undefined) &&
    configuration.showCookieConsent === true;

  const sendVerification = async () => {
    if (customerData.memberuserid) {
      await sendMessage(sendEmailVerification(customerData.memberuserid));

      if (currentStep === 1 && !loading) {
        updateStep(2);
      }
    }
  };

  const checkVerification = () => {
    if (currentStep === 2 && customerData.state === "VERIFICATION_SENT") {
      updateStep(1);
      setEmail("");
      setVerifyEmail(false);
    }
  };

  const style = {
    background: loginview.imageBackground ? `url(${loginview.imageBackground})` : "",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "cover",
  };

  useEffect(() => {
    if ((customerData.state === "NEED_VERIFICATION") &&
    currentStep === 1 &&
    verifyEmail === false) {
      setEmail(customerData.email);

      setVerifyEmail(true);
    }
  }, [customerData]);

  const loginViewLogoSrc = loginview.logo || "";

  return (
    <CmsData.Provider url="cookie-consent">
      <div className={cn(styles.block, "awardit-GuestAppContent")} style={style}>
        <Helmet
          titleTemplate={`${configuration.pageTitlePrefix || ""} %s ${configuration.pageTitleSuffix || ""}`}
          link={getFavicons(configuration)}
          htmlAttributes={{ lang: info.locale.slice(0, 2) }}
        />
        <header
          className={styles.header}
        >
          <Wrapper className={styles.headerWrapper}>
            <Link to="/">
              <Logo
                width={isSmall ? configuration.logoWidthSmall : configuration.logoWidthLarge}
                loginViewLogo={loginViewLogoSrc}
              />
            </Link>
            {loginview.customerServiceButtonUrl &&
            <nav>
              <a
                href={loginview.customerServiceButtonUrl}
                className={cn("awardit-GuestLink", styles.spacing)}
              >
                {loginview.customerServiceButtonText}
              </a>
              {consentActivated &&
                <Button
                  className="awardit-GuestLink"
                  onClick={() => sendMessage(setMode(MODE.COOKIE_CONSENT))}
                >
                  {t("FOOTER.NEED_HELP.COOKIES")}
                </Button>
              }
            </nav>
            }
          </Wrapper>
        </header>
        <div className={styles.wrapper}>
          {children}
          <SystemMessages />

          {location.pathname === routes.loginView.url &&
            <div>
              <CookieConsent />
            </div>
          }

          {verifyEmail === true &&
            <VerifyEmailView
              loading={loading}
              modalOpen={verifyEmail}
              setModalOpen={open => {
                if (!open) {
                  checkVerification();
                }
              }}
              updateStep={() => {
                sendVerification();
              }}
              customerEmail={email}
              currentStep={currentStep}
            />
          }
        </div>
      </div>
    </CmsData.Provider>
  );
};

export default GuestApp;
